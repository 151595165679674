export const MODULE = "AUTH";

export const AUTHENTICATE = "AUTHENTICATE";
export const CHECK_AUTH = "CHECK_AUTH";
export const LOGIN = "LOGIN";
export const SIGN_OUT = "SIGN_OUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const ACTIVATE_ACCOUNT = "ACTIVATE_ACCOUNT";
export const VERIFY_RESET_CODE = "VERIFY_RESET_CODE";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const REGISTER = "REGISTER";
export const PROFILE = "PROFILE";
export const RESET_AUTH = "RESET_AUTH";
export const GET_PERMISSIONS = "GET_PERMISSIONS";
export const PERMISSIONS = "PERMISSIONS";

export const GET_COUNTRY = `${MODULE}/GET_COUNTRY`;
export const GOT_COUNTRY = `${MODULE}/GOT_COUNTRY`;

export const GET_CATEGORY = `${MODULE}/GET_CATEGORY`;
export const GOT_CATEGORY = `${MODULE}/GOT_CATEGORY`;

export const GET_TESTIMONIAL = `${MODULE}/GET_TESTIMONIAL`;
export const GOT_TESTIMONIAL = `${MODULE}/GOT_TESTIMONIAL`;

export const GET_FAQ = `${MODULE}/GET_FAQ`;
export const GOT_FAQ = `${MODULE}/GOT_FAQ`;

export const GET_CLIENT = `${MODULE}/GET_CLIENT`;
export const GOT_CLIENT = `${MODULE}/GOT_CLIENT`;

export const GET_LOCATION = `${MODULE}/GET_LOCATION`;
export const GOT_LOCATION = `${MODULE}/GOT_LOCATION`;

export const GET_JOBS = `${MODULE}/GET_JOBS`;
export const GOT_JOBS = `${MODULE}/GOT_JOBS`;

export const GET_JOB_DETAILS = `${MODULE}/GET_JOB_DETAILS`;
export const GOT_JOB_DETAILS = `${MODULE}/GOT_JOB_DETAILS`;

export const APPLY = `${MODULE}/APPLY`;
export const SAVE = `${MODULE}/SAVE`;
export const FORGOT = `${MODULE}/FORGOT`;
export const CONTACT = `${MODULE}/CONTACT`;
export const CONTACT_RECRUITMENT = `${MODULE}/CONTACT_RECRUITMENT`;

export const CLEAR_LIST = `${MODULE}/CLEAR_LIST`;

export const GET_NEWS = `${MODULE}/GET_NEWS`;
export const GOT_NEWS = `${MODULE}/GOT_NEWS`;

export const GET_ADVISORIES = `${MODULE}/GET_ADVISORIES`;
export const GOT_ADVISORIES = `${MODULE}/GOT_ADVISORIES`;

export const GET_INTERVIEW = `${MODULE}/GET_INTERVIEW`;
export const GOT_INTERVIEW = `${MODULE}/GOT_INTERVIEW`;

export const GET_RECOVER = `${MODULE}/GET_RECOVER`;
export const GOT_RECOVER = `${MODULE}/GOT_RECOVER`;

export const GET_REMOVE_ACCOUNT = `${MODULE}/GET_REMOVE_ACCOUNT`;
export const GOT_REMOVE_ACCOUNT = `${MODULE}/GOT_REMOVE_ACCOUNT`;

export const GET_REMOVE_ACCOUNT_CONFIRM = `${MODULE}/GET_REMOVE_ACCOUNT_CONFIRM`;
export const GOT_REMOVE_ACCOUNT_CONFIRM = `${MODULE}/GOT_REMOVE_ACCOUNT_CONFIRM`;

export const GET_NEWS_DETAILS = `${MODULE}/GET_NEWS_DETAILS`;
export const GOT_NEWS_DETAILS = `${MODULE}/GOT_NEWS_DETAILS`;

export const GET_LINKS = `${MODULE}/GET_LINKS`;
export const GOT_LINKS = `${MODULE}/GOT_LINKS`;

export const SET_FORM_DATA = `${MODULE}/SET_FORM_DATA-A`;
export const SET_LOGIN_DATA = `${MODULE}/SET_LOGIN_DATA`;
export const CLEAR_FORM_DATA = `${MODULE}/CLEAR_FORM_DATA-A`;

export const GET_CLIENT_LOGOS = `${MODULE}/GET_CLIENT_LOGOS`;
export const GOT_CLIENT_LOGOS = `${MODULE}/GOT_CLIENT_LOGOS`;

export const GET_CLIENT_TESTIMONIAL = `${MODULE}/GET_CLIENT_TESTIMONIAL`;
export const GOT_CLIENT_TESTIMONIAL = `${MODULE}/GOT_CLIENT_TESTIMONIAL`;

export const GET_CHECK_USER = `${MODULE}/GET_CHECK_USER`;
export const GOT_CHECK_USER = `${MODULE}/GOT_CHECK_USER`;
